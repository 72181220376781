import React, { Component, Fragment } from 'react';
import Countdown from 'react-countdown-now';
import cx from 'classnames';

function BuyButton(props) {
  return (
    <a className="product-buy" onClick={props.onClick}>
      <span className="product-buy-price">
        ${props.price}
        .00 <span className="product-buy-curr">USD</span>
      </span>
      <div className="product-buy-border" />
      <span className="product-buy-text">BUY</span>
    </a>
  );
}

function DisabledButton(props) {
  return <div className="product-disabled">{props.children}</div>;
}
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      min: 1,
      max: 1000
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async handleClick() {
    if (this.props.data && this.props.client) {
      const { quantity } = this.state;
      const { data, client } = this.props;
      try {
        const checkout = await client.checkout.create();
        const itemsToAdd = [
          {
            variantId: data.variants[0].id,
            quantity
          }
        ];
        await client.checkout.addLineItems(checkout.id, itemsToAdd);
        window.location.href = checkout.webUrl;
      } catch (e) {
        console.error(e);
      }
    }
  }
  handleChange(e) {
    const { min, max } = this.state;
    const nq = parseInt(e.target.value, 10);
    if ((!isNaN(nq) && nq >= min && nq <= max) || e.target.value === '') {
      this.setState({
        quantity: parseInt(e.target.value, 10) || ''
      });
    }
  }
  render() {
    const { quantity } = this.state;
    const { index, data, focused, hoverable } = this.props;
    const comingSoon = parseFloat(data.variants[0].price) === 0; // Remove true to get back to normal countdown
    const showTba = true;
    const countDown = (
      <div className="product-countdown">
        <Countdown
          date={Date.UTC(2019, 1, 15, 24, 0, 0)}
          renderer={({ days, hours, minutes, seconds }) => {
            return (
              <Fragment>
                <span className="days">{days} DAYS</span>
                <br />
                <span className="hours">{hours} HOURS</span>
                <br />
                <span className="minutes">{minutes} MINUTES</span>
              </Fragment>
            );
          }}
        />
      </div>
    );
    // const tba = (
    //   <div className="product-tba">
    //     RELEASE DATE <br />
    //     TO BE ANNOUNCED
    //   </div>
    // );
    const tba = false;
    return (
      <div
        className={cx({
          product: true,
          focused,
          hoverable: hoverable && !comingSoon,
          blurred: comingSoon
        })}
        onClick={this.props.onClick}
      >
        <div className="product-title">
          {comingSoon || (
            <Fragment>
              ENTRY 0{index + 1}
              <span className="product-title-dot">·</span>
              <span className="product-title-gray">{data.title}</span>
            </Fragment>
          )}
          {comingSoon ? `ENTRY 0${index + 1}` : null}
        </div>
        <div className="product-preview">
          {/* {comingSoon && showTba ? (showTba ? tba : countDown) : null} */}
          {comingSoon ? countDown : null}
          <div
            className="product-image front"
            style={{ backgroundImage: `url(${data.images[1].src})` }}
          />
          <div
            className="product-image first"
            style={{ backgroundImage: `url(${data.images[0].src})` }}
          />
          <div
            className="product-image second"
            style={{ backgroundImage: `url(${data.images[0].src})` }}
          />
          <div
            className="product-image third"
            style={{ backgroundImage: `url(${data.images[0].src})` }}
          />
        </div>
        {data.variants[0].available ? (
          <Fragment>
            <div className="product-quantity">
              <span>Deck Quantity</span>
              <input
                pattern="[0-9]*"
                type="text"
                className="product-quantity-input"
                onChange={this.handleChange}
                value={quantity}
              />
            </div>
            <BuyButton
              price={parseFloat(data.variants[0].price)}
              onClick={this.handleClick}
            />
          </Fragment>
        ) : parseFloat(data.variants[0].price) > 0 ? (
          <DisabledButton>SOLD OUT</DisabledButton>
        ) : (
          <DisabledButton>COMING SOON</DisabledButton>
        )}
      </div>
    );
  }
}

export default Product;
