import React, { Component, useState } from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import cx from 'classnames';

import Carousel from './Carousel';
import Logo from './images/Logo.svg';

class About extends Component {
  constructor() {
    super();
    this.state = {
      date: null
    };
  }
  componentDidMount() {
    let date = DateTime.local().setZone('America/Los_Angeles');
    this.setState({
      date
    });
    this.timer = setInterval(() => {
      this.setState({
        date: DateTime.local().setZone('America/Los_Angeles')
      });
    }, 60000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    const { date } = this.state;
    return (
      <div
        className={cx({
          about: true,
          visible: true
        })}
      >
        <div className="stamp" />
        <div className="about-inner">
          <div className="about-content">
            <div className="about-scrollable">
              <p>
                As cardistry grows as an art form, there is a need for playing
                cards that are both beautifully designed and handle perfectly.
                Entry creates these playing cards. Entry decks advance the
                aesthetics of cardistry and are intended for use by cardists of
                all skill levels and styles. We work with the top designers and
                manufacturers in the world to ensure our decks always meet the
                highest standards.
              </p>
              <p>
                Unlike other companies who produce a range of different
                products, our attention is invested solely in one place — we
                focus exclusively on creating the best decks for cardistry. Our
                original system allows us to quickly and consistently produce
                unique decks that fulfil both the visual and physical needs of
                every cardist.
              </p>
              <p>
                Our playing cards are produced to the finest quality in close
                partnership with Dan & Dave Industries Inc, Art of Play®, and
                The United States Playing Card Company.
              </p>
              <p className="about-content-gray">
                © Entry, LLC · All Rights Reserved
                <br />
                4698 Alvarado Canyon Rd.
                <br />
                San Diego, California 92120
                <br />
                United States
              </p>
              <p className="about-content-date">
                Current Local Time:
                <br />
                San Diego, California
                <br />
                {date ? date.toFormat('EEEE d, MMMM') : ''},{' '}
                {date ? date.toFormat('t') : ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    console.log(e.target.value);
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  };
}

function RadioGroup(props) {
  const { options, name, value, ...rest } = props;
  return (
    <div className="amba-radiogroup">
      {options.map(opt => {
        const id = `amba-radio__${opt}-${name}`;
        return (
          <div className="amba-radio" key={id}>
            <input type="radio" id={id} name={name} value={opt} {...rest} />
            <label htmlFor={id}>{opt}</label>
          </div>
        );
      })}
    </div>
  );
}

function Ambassador() {
  const name = useFormInput('');
  const instagram = useFormInput('');
  const about = useFormInput('');
  const address = useFormInput('');

  // Radio
  const experience = useFormInput('');
  const ownDecks = useFormInput('');
  const onlyEntry = useFormInput('');

  function submit() {
    const vals = [
      name,
      instagram,
      about,
      address,
      experience,
      ownDecks,
      onlyEntry
    ];
    if (!vals.map(i => i.value).includes('')) {
      console.log('all filled');
    }
  }

  return (
    <div className="amba visible">
      <form className="amba-form" autoComplete="off">
        <div className="amba-form__section">
          <div>What's your full name?</div>
          <input
            required
            name="name"
            type="text"
            placeholder="Full name"
            autoComplete="off"
            {...name}
          />
        </div>
        <div className="amba-form__section">
          <div>What's your Instagram handle?</div>
          <input
            required
            name="instagram"
            type="text"
            placeholder="@username"
            autoComplete="off"
            {...instagram}
          />
        </div>
        <div className="amba-form__section">
          <div>How long have your been doing cardistry?</div>
          <RadioGroup
            options={['Less than 1 year', '1-2 years', '2-5 years', '5+ years']}
            name="experience"
            {...experience}
          />
        </div>
        <div className="amba-form__section">
          <div>Have you bought/do you currently own any Entry decks?</div>
          <RadioGroup
            options={['Yes', 'No']}
            name="own_entry_decks"
            {...ownDecks}
          />
        </div>
        <div className="amba-form__section">
          <div>
            If selected to be an ambasador, are you willing to only use Entry
            decks for the duration of the program?
          </div>
          <RadioGroup
            options={['Yes', 'No']}
            name="only_use_entry"
            {...onlyEntry}
          />
        </div>
        <div className="amba-form__section">
          <div>Why would you make a good ambassador for Entry? </div>
          <textarea
            required
            rows="5"
            name="why"
            placeholder="Tell us about yourself and what makes you great."
            {...about}
          />
        </div>
        <div className="amba-form__section">
          <div>Finally, what’s your address?</div>
          <input
            required
            name="address"
            type="text"
            placeholder="Full address"
            autoComplete="off"
            {...address}
          />
        </div>
        <input
          type="submit"
          onClick={submit}
          className="amba-submit"
          value="APPLY TO BE AN AMBASSADOR"
        />
      </form>
    </div>
  );
}

class App extends Component {
  constructor() {
    super();
  }
  // prettier-ignore
  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <Link
            to="/"
            className="logo"
            style={{ backgroundImage: `url(${Logo})` }}
          />
          <Route path="/" exact component={Carousel} />
          <Route path="/ambassador" exact component={Ambassador} />
          <Route path="/about" exact component={About} />
          <div className="footer">
            <ul className="footer-navigation">
              <li>
                <Link to="/about">About</Link>
                <span
                  style={{
                    transform: 'translateY(1px)',
                    display: 'inline-block',
                  }}
                >·</span>
              </li>
              <li>
                <a href="https://www.instagram.com/entry/" target="_blank">
                  Instagram
                </a>
                <span
                  style={{
                    transform: 'translateY(1px)',
                    display: 'inline-block',
                  }}
                >·</span>
              </li>
              <li>
                <a href="https://twitter.com/entry/" target="_blank">
                  Twitter
                </a>
                <span
                  style={{
                    transform: 'translateY(1px)',
                    display: 'inline-block',
                  }}
                >·</span>
              </li>
              <li>
                <a href="mailto:support@entry.us.com">
                  Wholesale
                </a>
                {/* <span
                  style={{
                    transform: 'translateY(1px)',
                    display: 'inline-block',
                  }}
                >·</span> */}
              </li>
              {/* <li>
                <Link to="/ambassador">Ambassador</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
