import React, { Component } from 'react';
import ShopifyClient from 'shopify-buy';
import cx from 'classnames';
import Product from './Product';
import preload from 'prefetch-image';

const url = window.location.href;
const queryString = url ? url.split('?')[1] : window.location.search.slice(1);
const accessToken =
  queryString === 'staging'
    ? 'a20a2f5d3758d742679de6678eeeed69'
    : 'fb3946bf34cc661bd88388534eac20c9';
const client = ShopifyClient.buildClient({
  domain: 'entry.shopping',
  storefrontAccessToken: accessToken
});

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      focusIndex: 3,
      targetIndex: 3,
      visible: false
    };
    this.focusProduct = this.focusProduct.bind(this);
    this.loadProductData = this.loadProductData.bind(this);
  }
  componentDidMount() {
    this.loadProductData();
  }
  async loadProductData() {
    try {
      const products = await client.product.fetchAll();
      const imageSrcs = [];
      products.forEach(product => {
        product.images.forEach(image => {
          imageSrcs.push(image.src);
        });
      });

      await preload(
        imageSrcs.filter((src, index, self) => self.indexOf(src) === index)
      );
      this.setState({
        products,
        focusIndex: products.length - 1,
        targetIndex: products.length - 1,
        visible: true
      });
    } catch (e) {
      console.error(e);
    }
  }
  focusProduct(index) {
    this.setState(
      {
        targetIndex: index
      },
      () => {
        setTimeout(() => {
          this.setState({ focusIndex: index });
        }, 420);
      }
    );
  }
  render() {
    const width = 198;
    const margin = 90;
    const { products, focusIndex, targetIndex, visible } = this.state;
    const style = {
      transform: `translateX(${-targetIndex * (width + margin)}px)`
    };
    return (
      <div
        className={cx({
          carousel: true,
          visible
        })}
      >
        <div className="carousel-inner" style={style}>
          {products.map((product, index) => (
            <Product
              index={index}
              key={`product-${index}`}
              data={product}
              client={client}
              focused={index === targetIndex}
              hoverable={index === focusIndex}
              onClick={() => this.focusProduct(index)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Carousel;
